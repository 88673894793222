import { useEffect, useState } from 'react';
import { getFullValue } from 'utils';
import { FormDialog } from 'components/FormDialog';
import { EditIcon } from 'assets/icons';
import { detailInfoItemStyles } from './styles';

interface DetailInfoItemProps {
  edit: boolean;
  name: string;
  fieldName: string;
  value: string | number;
  inputType?: string;
  placeholder?: string;
  title?: string;
  filling?: boolean;
  mask?: string;
}

const { Container } = detailInfoItemStyles;

export const DetailInfoItem = ({
  edit,
  name,
  fieldName,
  value,
  inputType,
  placeholder,
  title,
  filling,
  mask,
}: DetailInfoItemProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const fullValue = getFullValue(fieldName, value);
  const inputProps = {
    type: inputType,
    defaultValue: value,
    fieldName,
    placeholder,
    mask,
  };

  const toggleModalShow = (show: boolean) => {
    setModalIsOpen(show);
  };

  return (
    <Container filling={filling} type={inputType}>
      <span>{name}</span>
      <div className="content-info">
        <span className="title-info">
          {value ? (
            <div className="title-info">{fullValue}</div>
          ) : (
            <div className="empty-info">{placeholder}</div>
          )}
        </span>
        {edit && (
          <>
            <button onClick={() => toggleModalShow(true)}>
              <EditIcon />
            </button>
            {modalIsOpen ? (
              <FormDialog
                title={title ? title : name}
                show={modalIsOpen}
                toggleShow={toggleModalShow}
                {...inputProps}
              />
            ) : null}
          </>
        )}
      </div>
    </Container>
  );
};

export default DetailInfoItem;

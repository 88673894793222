import {
  AffordIcon,
  CancelIcon,
  ConfirmIcon,
  DocumentIcon,
  PauseIcon,
  PrepaymentIcon,
  ProcessIcon,
  SuccessIcon,
  SupplyIcon,
  WarningIcon,
  CheckIcon,
  CarIcon,
} from 'assets/icons/statuses/';

interface IconsCollectionProps {
  [name: string]: JSX.Element;
}

export const iconsCollection: IconsCollectionProps = {
  ['Выдано']: <AffordIcon />,
  ['Одобрение']: <SuccessIcon />,
  ['Отказ клиента']: <CancelIcon />,
  ['Одобрен']: <ConfirmIcon />,
  ['Сбор документов']: <DocumentIcon />,
  ['Отложил покупку']: <PauseIcon />,
  ['Получен аванс']: <PrepaymentIcon />,
  ['В работе']: <ProcessIcon />,
  ['Поставка техники']: <SupplyIcon />,
  ['Новая']: <WarningIcon />,
  ['Подбор техники']: <CarIcon />,
  ['Выбор графика']: <CheckIcon />,
};

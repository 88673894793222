import { DynamicIcon } from 'components/DynamicIcon';
import { SuccessIcon } from 'assets/icons/statuses';
import styles from './styles.module.scss';
interface ApplicationStatusProps {
  status: string;
  text?: string;
  control?: boolean;
  selected?: boolean;
  onChange?: (value: any) => void;
  theme?: string;
}

const { wrapper, title, active, select, description } = styles;

export const ApplicationStatus = ({
  status,
  control,
  text,
  selected,
  onChange,
  theme,
}: ApplicationStatusProps) => {
  const statusToggle = () => {
    if (onChange && !selected) onChange(status);
  };

  return (
    <>
      {!selected ? (
        <button
          className={`${wrapper} ${control && theme !== 'option' ? active : ''} ${
            status === 'Отказ клиента' && theme === 'option' ? styles['red'] : ''
          } ${theme ? styles[theme] : ''}`}
          onClick={statusToggle}
        >
          {text && status === 'Новая' ? <SuccessIcon /> : <DynamicIcon name={status} />}
          <div className={title}>{text ? text : status}</div>
        </button>
      ) : (
        <button
          className={`${wrapper} ${select} ${control ? active : ''} ${
            theme ? styles[theme] : ''
          }`}
        >
          <DynamicIcon name={status} />
          <div className={description}>
            <span>Статус</span>
            <div className={title}>{status}</div>
          </div>
        </button>
      )}
    </>
  );
};

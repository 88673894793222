import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';
import { OptionsProps } from 'utils/ts/interfaces';

interface FiltersProps {
  filters: {
    managers: OptionsProps[];
    statuses: OptionsProps[];
  };
}
const initialState: FiltersProps = { filters: { managers: [], statuses: [] } };

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setManagers: (state, { payload }: PayloadAction<OptionsProps[]>) => {
      state.filters = { ...state.filters, managers: payload };
    },
    setStatuses: (state, { payload }: PayloadAction<string[]>) => {
      const newStatuses = payload.map((item, index) => ({ id: index, value: item }));
      state.filters = { ...state.filters, statuses: newStatuses };
    },
  },
});

export const { setManagers, setStatuses } = slice.actions;

export const selectFilters = (state: RootState) => {
  return state.filters;
};

export default slice.reducer;

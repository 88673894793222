export { ReactComponent as AffordIcon } from './afford.svg';
export { ReactComponent as CancelIcon } from './cancel.svg';
export { ReactComponent as ConfirmIcon } from './confirm.svg';
export { ReactComponent as DocumentIcon } from './document.svg';
export { ReactComponent as PauseIcon } from './pause.svg';
export { ReactComponent as PrepaymentIcon } from './prepayment.svg';
export { ReactComponent as ProcessIcon } from './process.svg';
export { ReactComponent as SuccessIcon } from './success.svg';
export { ReactComponent as SupplyIcon } from './supply.svg';
export { ReactComponent as WarningIcon } from './warning.svg';
export { ReactComponent as CarIcon } from './car.svg';
export { ReactComponent as CheckIcon } from './check.svg';

export { baseApiUrl, defaultValueForDetails, primaryPaths } from './constants';
export {
  getFullValue,
  getStatusTheme,
  getGridTemplate,
  findApplicationPayment,
  getActiveStatus,
  numberWithSpaces,
  getFileNameFromUrl,
  getValueFromArray,
  addedErrorOnField,
} from './dataTransformation';
export { iconsCollection } from './IconsCollection';
export {
  getTokenFromStorage,
  getTokenFromStore,
  clearLocalStorage,
  setTokenFromStorage,
} from './localStorage';
export { createUrl, sendRequest, getContent } from './requests';
export { changeHeaderPropsInTable } from './table';
export { determinePageNumber } from './determinePageNumber';
export { addTitlePage } from './addTitlePage';

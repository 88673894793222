import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setMainLoader } from 'store/slices/mainLoader';
import { getContent } from 'utils/requests';
import { changeHeaderPropsInTable } from 'utils/table';
import { UrlProps } from 'utils/ts/interfaces';
import { table, tabs } from 'assets/data/applications';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';
import { Pagination } from 'components/Pagination';
import { Loader } from 'components/Loader';
import { applicationBoardStyles } from './styles';
import { Search } from 'components/Search';
import { Filter } from 'components/Filter';
import { useFiltersCheck } from 'hooks';
import { useGetManagersMutation, useGetStatusesMutation } from 'hooks/useApiRequest';
import { setManagers, setStatuses } from 'store/slices/filters';

interface ApplicationBoardProps {
  status: string;
  param?: string;
  commonUrl: string;
}

const { Wrapper, Content, FiltersBar, FiltersClear, Button } = applicationBoardStyles;

export const ApplicationBoard = ({ status, param, commonUrl }: ApplicationBoardProps) => {
  const [content, setContent] = useState<any>([]);
  const [countPages, setCountPages] = useState(0);
  const locationQuery = useLocation().search;
  const [activeTab, setActiveTab] = useState(param);
  const [loading, setLoading] = useState(false);
  const [tableProps, setTableProps] = useState(
    changeHeaderPropsInTable(table, activeTab),
  );
  const dispatch = useDispatch();
  const { managers, statuses } = useFiltersCheck();
  const type = 'application';
  const [searchParams, setSearchParams] = useSearchParams();
  const [getManagers] = useGetManagersMutation();
  const [getStatuses] = useGetStatusesMutation();
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';
  const filterStatus = searchParams.get('status') || '';
  const filterManager = searchParams.get('manager') || '';
  let params: UrlProps = {
    content: type,
    status: status + '/',
    query: locationQuery,
  };

  if (param) {
    params = { ...params, activeTab };
  }

  const clearFilters = () => {
    setSearchParams({ page: '1' });
  };

  const sendRequest = (config: UrlProps) => {
    getContent(config).then(({ data }: any) => {
      const { result, pages, countNewApp } = data;
      setCountPages(pages);
      setTimeout(() => {
        setContent(result);
        setLoading(false);
      }, 250);
      dispatch(setMainLoader(false));
      if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
    });
  };

  useEffect(() => {
    if (locationQuery) {
      setLoading(true);
      params.query = locationQuery;
      params.activeTab = activeTab;
      sendRequest(params);
    }
  }, [page, search, filterStatus, filterManager]);

  useEffect(() => {
    if (!locationQuery) {
      setLoading(true);
      params.activeTab = activeTab;
      params.query = '?page=1';
      sendRequest(params);
    }
    setTableProps(changeHeaderPropsInTable(table, activeTab));
  }, [activeTab]);

  useEffect(() => {
    if (!managers.length) {
      getManagers()
        .unwrap()
        .then((data: any) => {
          dispatch(setManagers(data));
        });
    }
    if (!statuses.length) {
      getStatuses()
        .unwrap()
        .then((data: any) => {
          dispatch(setStatuses(data));
        });
    }
  }, []);

  return (
    <Wrapper>
      <FiltersBar>
        <Search defaultValue={search} />
        {managers.length > 0 && (
          <Filter
            name="manager"
            value={filterManager}
            options={managers}
            label="Менеджер"
          />
        )}
        {statuses.length > 0 && (
          <Filter name="status" value={filterStatus} options={statuses} label="Статус" />
        )}
      </FiltersBar>
      <FiltersClear>
        <Button onClick={clearFilters}>Сбросить фильтры</Button>
      </FiltersClear>
      <Content>
        <Tabs
          tabs={param ? tabs : []}
          changeParamUrl={setActiveTab}
          commonUrl={commonUrl}
        >
          <Loader active={loading}>
            <Table
              content={content}
              {...tableProps}
              type={type}
              activeTab={param ? activeTab : status}
            />
          </Loader>
          <Pagination count={countPages} />
        </Tabs>
      </Content>
    </Wrapper>
  );
};

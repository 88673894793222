import { PaymentItemProps, InputDataProps, ErrorProps } from 'utils/ts/interfaces';
import { RubleIcon } from 'assets/icons';

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0';
  } else {
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
};
export const getFileNameFromUrl = (url: string) => {
  const name = url.split('/')?.pop();
  return decodeURI(name || '');
};
export const numberWithSpaces = (value: number | string) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const getFullValue = (field: string, value: string | number) => {
  let newValue: any = '';
  switch (field) {
    case 'cost':
    case 'amount':
      newValue = (
        <>
          {numberWithSpaces(value)}
          <RubleIcon />
        </>
      );
      break;
    case 'limitation':
      newValue = `${value} мес.`;
      break;
    case 'advance':
      newValue = `${value} %`;
      break;
    default:
      newValue = `${value ? value : ''}`;
      break;
  }
  return newValue;
};

export const getStatusTheme = (status: string) => {
  switch (status) {
    case 'Выдано':
      return 'green';
    case 'Отказ клиента':
      return 'red';
    default:
      return '';
  }
};

export const getGridTemplate = ({
  countColumn,
  type,
  tab,
  edited,
}: {
  countColumn: number;
  type: string;
  tab?: string;
  edited?: boolean;
}) => {
  switch (type) {
    case 'application':
      return tab === 'with-reg' || tab === 'finished'
        ? `100px 80px repeat(4, 1fr) 181px ${edited ? '24px' : ''}`
        : `51px 80px repeat(3, 1fr) 181px ${edited ? '24px' : ''}`;
    case 'clients':
      return 'repeat(5, 1fr)';
    case 'dealerships':
      return `100px 1fr ${edited ? '24px' : ''}`;
    default:
      return `repeat(${countColumn}, 1fr) ${edited ? '24px' : ''}`;
  }
};

export const findApplicationPayment = (
  applicationId: number | string,
  payments: PaymentItemProps[],
) => {
  const element = payments.filter(item => item.applicationId !== applicationId);
  return element[0];
};

export const getActiveStatus = (status: string) => {
  switch (status) {
    case 'Новая':
      return 'new';
    case 'Выдано':
    case 'Отказ клиента':
    case 'Отложил покупку':
      return 'finished';
    default:
      return 'underway';
  }
};

export const getValueFromArray = (array: InputDataProps[]) => {
  let values = {};
  array.map(({ field, value, disabled, returnOptionId }) => {
    if (!disabled) {
      // @ts-ignore
      values = { ...values, [field]: returnOptionId ? value.id : value };
    }
  });

  return values;
};

export const addedErrorOnField = (errors: ErrorProps[], inputs: InputDataProps[]) => {
  const newInputs = inputs.map(item => {
    errors.map(({ field, message }) => {
      if (field === item.field) {
        item = {
          ...item,
          helperText: message,
          invalid: true,
        };
      }
    });

    return item;
  });

  return newInputs;
};

import { FileIcon } from 'assets/icons';
import { formatBytes } from 'utils/dataTransformation';
import {
  Title,
  ImageWrapper,
  Link,
  Button,
  LinkWrapper,
  TitleWrapper,
  Size,
} from './styles';

interface FileDownloadItem {
  id: number;
  name: string;
  url: string;
  size: number;
}

export const FileDownloadItem = ({ id, name, url, size }: FileDownloadItem) => {
  return (
    <LinkWrapper>
      <Link>
        <ImageWrapper>
          <FileIcon />
        </ImageWrapper>
        <TitleWrapper>
          <Title>{name}</Title>
          <Size>{formatBytes(size)}</Size>
        </TitleWrapper>
      </Link>
      <Button href={url} download>
        Скачать
      </Button>
    </LinkWrapper>
  );
};

export default FileDownloadItem;

import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Select as SelectMUI, MenuItem, FormControl, OutlinedInput } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { OptionsProps } from 'utils/ts/interfaces';
import { ApplicationStatus } from 'components/ApplicationStatus';
import styles from './styles.module.scss';

interface SelectProps {
  active: string;
  options: OptionsProps[];
  onChange: (value: any) => void;
  disabled?: boolean;
}

const MenuProps = {
  PaperProps: {
    style: {
      width: 220,
      minWidth: 'none',
      boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.15)',
      borderRadius: 10,
      marginTop: 8,
    },
  },
};

function getStyles(name: string, personName: string, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const { option, hidden, input } = styles;

export const SelectStatus = ({ active, options, onChange, disabled }: SelectProps) => {
  const theme = useTheme();
  const [status, setStatus] = useState<string>(active);

  useEffect(() => {
    if (status !== active) setStatus(active);
  }, [active]);

  const handleChange = (event: SelectChangeEvent<typeof status>) => {
    if (!disabled) {
      const {
        target: { value },
      } = event;
      setStatus(value);
      onChange(value);
    }
  };

  return (
    <div className="select">
      <FormControl sx={{ m: 1, width: 220 }} disabled={disabled}>
        <SelectMUI
          id="demo-name"
          value={status}
          onChange={handleChange}
          input={<OutlinedInput className={`${input} input`} />}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {options.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              style={getStyles(item.value, status, theme)}
              className={`${option} ${status === item.value ? hidden : ''}`}
            >
              <ApplicationStatus
                status={item.value}
                control={true}
                selected={status === item.value && true}
                theme={'option'}
              />
            </MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    </div>
  );
};

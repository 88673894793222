import { DetailInfoProps } from './ts/interfaces';

export const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const defaultValueForDetails: DetailInfoProps = {
  fullName: '',
  phoneNumber: '',
  companyName: '',
  email: '',
  uin: '',
  comment: '',
  limitation: '',
  managerComment: '',
  advance: '',
  cost: '',
  productName: '',
  updatedAt: '',
  id: '',
  status: 'Новая',
  manager: null,
  agent: null,
  payment: null,
  paymentSchedule: null,
  files: [],
  createdAt: '',
  anonymousUser: false,
  client: {
    fullName: '',
    email: '',
    phoneNumber: '',
    numberType: null,
    uin: '',
  },
  userId: null,
};

export const primaryPaths = [
  '/application/new',
  '/application/underway',
  '/application/finished',
  '/clients',
  '/agents',
  '/payments',
  '/settings',
];
